import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = props => (
  <Layout pathname={props.location.pathname}>
    <SEO title="Contact Us" />
    
    <div className="grid lg:grid-cols-2">
      <div>
        <h1>Contact Us</h1>

        <p>We'd love to hear from you!</p>

        <p>
          Phone:{" "}
          <strong>
            <a href="tel:61398441665">(03) 9844 1665</a>
          </strong>
        </p>

        <p>
          Email:{" "}
          <strong>
            <a href="mailto:info@burmpro.com.au">info@burmpro.com.au</a>
          </strong>
        </p>
      </div>
      <div>

        <h2>Online Form</h2>

        <form action="https://formspree.io/mvowdeqg" method="POST">

          <label className="block my-2">
              <span className="block my-2">Name:</span>
              <input type="text" name="name" className="block w-full px-4 py-2 border form-input border-gray lg:w-64" placeholder="Mike Smith" />
          </label>
          
          <label className="block my-2">
              <span className="block my-2">Email:</span>
              <input type="email" name="email" className="block w-full px-4 py-2 border form-input border-gray lg:w-64" placeholder="mike@.." />
          </label>
          
          <label className="block my-2">
              <span className="block my-2">Phone:</span>
              <input type="text" name="phone" className="block w-full px-4 py-2 border form-input border-gray lg:w-64" placeholder="0411.." />
          </label>

          <label className="block my-2">
              <span className="block my-2">Message:</span>
              <input type="text" name="message" className="block w-full px-4 py-2 border form-input border-gray lg:w-64" placeholder="Your questions or comments.." />
          </label>

          <input type="text" name="_gotcha" className="hidden" />

          <button className="block w-full px-12 py-3 mt-8 text-center text-white transition duration-300 bg-grey hover:bg-red lg:w-64">Submit</button>

        </form>
        
      </div>
    </div>
  </Layout>
)

export default ContactPage
